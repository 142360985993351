import React from "react";
import "./Pagination.css";

function Pagination({ pagedata, onPageChange }) {
  const getPageRangeText = () => {
    const startRange = (pagedata.page - 1) * pagedata.per_page + 1;
    const endRange = Math.min(
      pagedata.page * pagedata.per_page,
      pagedata.total
    );

    return `${startRange}-${endRange} of ${pagedata.total}`;
  };

  return (
    <div className="pagination">
      <p> Showing {getPageRangeText()}</p>
      <div className="paginationbtns">
        <button
          disabled={!pagedata.has_prev}
          className="pagination_button"
          onClick={() => onPageChange(pagedata.page - 1)}
        >
          Previous
        </button>

        <button className="pagination_activebutton">{pagedata.page}</button>

        <button
          style={{
            padding: "8px 12px",
            backgroundColor: "#f2f2f2",
            border: "1px solid #ddd",
            color: "#333",
            cursor: "pointer",
            transition: "background-color 0.3s ease",
            marginRight: "5px",
            ...(!pagedata.has_next && {
              cursor: "not-allowed",
              backgroundColor: "#ddd",
            }),
          }}
          disabled={!pagedata.has_next}
          onClick={() => onPageChange(pagedata.page + 1)}
          className="pagination_button"
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default Pagination;
