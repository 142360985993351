import * as React from "react";
import { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PeopleIcon from "@mui/icons-material/People";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ArticleIcon from "@mui/icons-material/Article";
import "./Sidebar.css";
import logo from "../../assets/LOGO_SWISSMOOOVING.png";
import Menu from "./Menu";
import { useEffect } from "react";

const drawerWidth = 250;

function ResponsiveDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [showmenu, setshowmenu] = useState(false);

  const location = useLocation();
  const sidebarRef = useRef(null);
  const menuref = useRef();
  const profile = JSON.parse(localStorage.getItem("Data")).profile_pic;

  useEffect(() => {
    localStorage.getItem("isuser");
    let handler = (event) => {
      if (!menuref.current.contains(event.target)) {
        setshowmenu(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const CheckPath = (path) => location.pathname === path;
  const drawer = (
    <div className="Sidebar">
      <img src={logo} alt="logo" className="sidebar_logo" />
      <li className="SidebarItem" onClick={() => setMobileOpen(false)}>
        <Link
          to="/Dashboard"
          style={
            CheckPath("/Dashboard")
              ? {
                  color: "#eb7475",
                }
              : {}
          }
        >
          <DashboardIcon />
          <p className="sidebarlabel">Dashboard</p>
        </Link>
      </li>
      <li className="SidebarItem" onClick={() => setMobileOpen(false)}>
        <Link
          to="/User_List"
          style={
            CheckPath("/User_List")
              ? {
                  color: "#eb7475",
                }
              : {}
          }
        >
          <PeopleIcon />

          <p className="sidebarlabel">Users</p>
        </Link>
      </li>
      {CheckPath("/User_Details") && (
        <li className="SidebarItem" onClick={() => setMobileOpen(false)}>
          <div style={{ color: "#eb7475" }}>
            <ArticleIcon />
            <p className="sidebarlabel">User Details</p>
          </div>
        </li>
      )}

      <li className="SidebarItem" onClick={() => setMobileOpen(false)}>
        <Link
          to="/Driver_List"
          style={
            CheckPath("/Driver_List")
              ? {
                  color: "#eb7475",
                }
              : {}
          }
        >
          <LocalShippingIcon />
          <p className="sidebarlabel">Drivers</p>
        </Link>
      </li>
      {CheckPath("/Driver_Details") && (
        <li className="SidebarItem" onClick={() => setMobileOpen(false)}>
          <div
            style={
              CheckPath("/Driver_Details")
                ? {
                    color: "#eb7475",
                  }
                : {}
            }
          >
            <ArticleIcon />

            <p className="sidebarlabel">Driver Details</p>
          </div>
        </li>
      )}

      <li className="SidebarItem" onClick={() => setMobileOpen(false)}>
        <Link
          to="/Order_List"
          style={
            CheckPath("/Order_List")
              ? {
                  color: "#eb7475",
                }
              : {}
          }
        >
          <ShoppingCartIcon />
          <p className="sidebarlabel">Orders</p>
        </Link>
      </li>
      {CheckPath("/Order_Details") && (
        <li className="SidebarItem" onClick={() => setMobileOpen(false)}>
          <div
            style={
              CheckPath("/Order_Details")
                ? {
                    color: "#eb7475",
                  }
                : {}
            }
          >
            <ArticleIcon />

            <p className="sidebarlabel">Order Details</p>
          </div>
        </li>
      )}
      <li className="SidebarItem" onClick={() => setMobileOpen(false)}>
        <Link
          to="/PrivacyPolicy?lng=english_content"
          style={
            CheckPath("/PrivacyPolicy")
              ? {
                  color: "#eb7475",
                }
              : {}
          }
        >
          <PrivacyTipIcon />
          <p className="sidebarlabel">Privacy Policy</p>
        </Link>
      </li>
      <li className="SidebarItem" onClick={() => setMobileOpen(false)}>
        <Link
          to="/AboutUs?lng=english_content"
          style={
            CheckPath("/AboutUs")
              ? {
                  color: "#eb7475",
                }
              : {}
          }
        >
          <FormatListBulletedIcon />
          <p className="sidebarlabel">About Us</p>
        </Link>
      </li>
      <li className="SidebarItem" onClick={() => setMobileOpen(false)}>
        <Link
          to="/Terms&Condition?lng=english_content"
          style={
            CheckPath("/Terms&Condition")
              ? {
                  color: "#eb7475",
                }
              : {}
          }
        >
          <InfoIcon />
          <p className="sidebarlabel">Terms&Condition</p>
        </Link>
      </li>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <div ref={sidebarRef}>
      <div style={{ backgroundColor: "#f0f1f4" }}>
        <div className="appbar">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              mr: 2,
              display: { md: "none" },
              color: "black",
            }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography variant="h6" noWrap component="div">
            Responsive drawer
          </Typography> */}
          <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
            {/* <Badge
              badgeContent={4}
              color="error"
              sx={{ cursor: "pointer" }}
              onClick={() => setshownotification(true)}
            >
              <NotificationsNoneIcon color="action" />
            </Badge> */}
            <img
              alt="profileimg"
              src={process.env.REACT_APP_IMAGE_URL_ADMIN + profile}
              className="appbarprofileimg"
              onClick={() => setshowmenu(true)}
            />
          </div>
        </div>
        <Box
          component="nav"
          sx={{
            width: { md: drawerWidth },
            flexShrink: { md: 0 },
            backgroundColor: "#f0f1f4",
            zIndex: 0,
          }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", md: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                backgroundColor: "#f0f1f4",
                // border: "none",
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
      </div>
      <div ref={menuref}>{showmenu && <Menu showmenu={setshowmenu} />}</div>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};

export default ResponsiveDrawer;
