import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./User.css";
import { userlist, usersearch } from "../../service/Mainservice";
import Pagination from "../Pagination/pagination";
import SearchIcon from "@mui/icons-material/Search";
import Loader from "../../Loader/Loader";

const UserList = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dummydata, setDummyData] = useState(data);
  const [searchvalue, setSearchValue] = useState("");
  const [pagination, setPagination] = useState({});
  const [status, setStatus] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const toDate = new Date();
  const navigate = useNavigate();
  const todayDate = new Date();

  useEffect(() => {
    getData(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async (page, searchValue, status, from_date, to_date) => {
    try {
      const apiFunction = searchValue
        ? usersearch(page, searchValue)
        : userlist(page, status || undefined, from_date, to_date);

      const response = await apiFunction;

      setData(response.data.data);
      setDummyData(response.data.data);
      setPagination(response.data.pagination);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.code === 401) {
        localStorage.clear();
        navigate("/");
      }
    }
  };

  const handlePageChange = (pageNumber) => {
    getData(pageNumber, searchvalue, status, fromDate, toDate);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    getData(1, value, status, fromDate, toDate);
  };

  const handleStatusChange = (event) => {
    const value = event.target.value;
    setStatus(value);
    getData(1, searchvalue, value, fromDate, toDate);
  };

  const handleDurationChange = (event) => {
    let from_date = null;
    const today = new Date();

    switch (event.target.value) {
      case "today":
        from_date = today;
        break;
      case "lastWeek":
        from_date = new Date(today.setDate(today.getDate() - 7));
        break;
      case "lastMonth":
        from_date = new Date(today.setMonth(today.getMonth() - 1));
        break;
      case "lastSixMonth":
        from_date = new Date(today.setMonth(today.getMonth() - 6));
        break;
      case "lastYear":
        from_date = new Date(today.setFullYear(today.getFullYear() - 1));
        break;
      default:
        from_date = null;
        break;
    }

    setFromDate(from_date);
    getData(1, searchvalue, status, from_date, todayDate);
  };

  return (
    <>
      <div className="container1">
        <div className="data_head">
          <div className="filter_main">
            <h1 className="data_heading">All User List</h1>
            <select id="status" name="status" className="selectfield" onChange={handleStatusChange}>
              <option value="">Select status</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
            <select id="duration" name="duration" className="selectfield" onChange={handleDurationChange}>
              <option value="">Select Duration</option>
              <option value="today">Today</option>
              <option value="lastWeek">Last Week</option>
              <option value="lastMonth">Last Month</option>
              <option value="lastSixMonth">Last 6 Months</option>
              <option value="lastYear">Last Year</option>
            </select>
          </div>
          <div className="searchicon">
            <SearchIcon />
            <input
              type="search"
              placeholder="Search..."
              id="search"
              value={searchvalue}
              onChange={handleSearch}
            />
          </div>
        </div>
        <div className="data_table_over">
          <table className="data_table">
            <thead>
              <tr>
                <th>User</th>
                <th>Mobile Number</th>
                <th>Email</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {dummydata?.map((item, index) => (
                <tr key={item.id}>
                  <td>{item.full_name}</td>
                  <td>+{item.country_code + item.phone_number}</td>
                  <td>{item.email}</td>
                  <td>
                    {item.is_block ? (
                      <span className="cancel_data">Deactive</span>
                    ) : (
                      <span className="complete_data">Active</span>
                    )}
                  </td>
                  <td>
                    <button
                      onClick={() =>
                        navigate("/User_Details", {
                          state: {
                            id: item.id,
                          },
                        })
                      }
                      className="data_button_view"
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination pagedata={pagination} onPageChange={handlePageChange} />
      </div>
      {loading && <Loader />}
    </>
  );
};

export default UserList;
