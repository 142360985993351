import axios from "axios";

const getToken = () => {
  return localStorage.getItem("ntk");
};

const headers = () => {
  const token = getToken();
  return {
    headers: {
      Authentication: token,
    },
  };
};
const headersForFile = () => {
  const token = getToken();
  return {
    headers: {
      Authentication: token,
    },
    responseType: "blob", // Ensure the response is treated as binary
  };
};

const profile = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}UpdateProfile`,
      headers()
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};
const editprofile = async (data, image) => {
  try {
    const params = new FormData();
    params.append("username", data.name);

    image && params.append("profile_pic", image);

    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}UpdateProfile`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};
const changepassword = async (values) => {
  try {
    const params = new FormData();
    params.append("old_password", values.oldpassword);
    params.append("new_password", values.password);

    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}Changepassword`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};
const dashboard = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}DashBoard`,
      headers()
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};
const userlist = async (value, status, from_date, to_date) => {
  try {
    const params = {
      page: value,
      status: status,
      from_date,
      to_date,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}UserList`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};
const usersearch = async (pageno, value) => {
  try {
    const params = {
      page: pageno,
      search_text: value,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}UserSearch`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};
const userdetails = async (value) => {
  try {
    const params = {
      id: value,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}UserDetails`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};
const driverlist = async (value, status, from_date, to_date) => {
  try {
    const params = {
      page: value,
      status,
      from_date,
      to_date,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}DriverList`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};
const driversearch = async (pageno, value) => {
  try {
    const params = {
      page: pageno,
      search_text: value,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}DriverSearch`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

const driverdetails = async (value) => {
  try {
    const params = {
      id: value,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}DriverDetails`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};
const active = async (value, setblock) => {
  try {
    const params = {
      id: value,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}UnBlockUser`,
      params,
      headers()
    );
    setblock(response.data.data.is_block);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};
const deactive = async (value, setblock) => {
  try {
    const params = {
      id: value,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}BlockUser`,
      params,
      headers()
    );
    setblock(response.data.data.is_block);

    return response;
  } catch (error) {
    throw error.response.data;
  }
};

const orderlist = async (value, userId, driverId, pick, drop) => {
  try {
    const params = {
      page: value,
      driver_id: driverId,
      user_id: userId,
      pick_address_city: pick,
      drop_address_city: drop,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}OrderTable`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};
const orderdetails = async (value) => {
  try {
    const params = {
      id: value,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}OrderDetails`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

const getdoc = async (value) => {
  try {
    const params = {
      id: value,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}LegalDocsData`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};
const updatedoc = async (value, lng, data) => {
  try {
    const params = {
      id: value,
      [lng]: data,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}UpdateLegalDocsData`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

const getFile = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}OrderExcelDownload`,
      headersForFile()
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export default profile;

export {
  editprofile,
  changepassword,
  dashboard,
  userlist,
  usersearch,
  userdetails,
  driverlist,
  driversearch,
  driverdetails,
  active,
  deactive,
  orderlist,
  orderdetails,
  getdoc,
  updatedoc,
  getFile,
};
