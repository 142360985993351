import { useState } from "react";
import { useEffect } from "react";
import Rating from "@mui/material/Rating";
import route from "../../assets/route.png";

import Loader from "../../Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { active, deactive, driverdetails } from "../../service/Mainservice";
import Imageview from "../ImageView";

const DriverDetails = () => {
  const [loading, setloading] = useState(true);
  const [showimage, setshowimage] = useState(false);
  const [link, setlink] = useState();
  const [data, setData] = useState([]);
  const [block, setblock] = useState();
  const location = useLocation();
  const id = location.state.id;
  const navigate = useNavigate();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    try {
      const data = await driverdetails(id);
      setloading(false);
      console.log(data);
      setData(data.data.data);
      setblock(data.data.data.is_block);
    } catch (error) {
      setloading(false);
      if (error.code === 401) {
        localStorage.clear();
        navigate("/");
      }
    }
  };
  return (
    <>
      <h1 className="userdetails_heading">Driver Details </h1>
      <div className="userdetails">
        <div className="userdetails_up">
          <div className="userprofile_up">
            <img
              src={process.env.REACT_APP_IMAGE_URL + data.profile_pic}
              alt="img"
              onClick={() => {
                setshowimage(true);
                setlink(process.env.REACT_APP_IMAGE_URL + data.profile_pic);
              }}
            />
            <h1 className="username">
              {data.full_name === null ? "Not Defined Yet" : data.full_name}
            </h1>
          </div>
          <div className="userprofile_down" style={{ textAlign: "center" }}>
            <Rating
              name="read-only"
              value={Number(data.rate)}
              precision={0.5}
              size="large"
              readOnly
            />
            <p>({data.reviews} Reviews)</p>
          </div>
        </div>
        <div className="userdetails_down">
          <p className="userdata">
            Email :
            <span>{data.email === null ? "Not Defined Yet" : data.email}</span>
          </p>
          <p className="userdata">
            Phone Mumber :
            <span>
              {data.phone_number === null
                ? "Not Defined Yet"
                : +data.country_code + data.phone_number}
            </span>
          </p>
          <p className="userdata">
            Account Create :
            <span>
              {data.date_created === null
                ? "Not Defined Yet"
                : data.date_created}
            </span>
          </p>
          <p className="userdata">
            Language :
            <span>
              {data.Language === null ? "Not Defined Yet" : data.Language}
            </span>
          </p>
          <p className="userdata">
            Address1 :
            <span>
              {data.addressline_1 === null
                ? "Not Defined Yet"
                : data.addressline_1}
            </span>
          </p>
          <p className="userdata">
            Address2 :
            <span>
              {data.addressline_2 === null
                ? "Not Defined Yet"
                : data.addressline_2}
            </span>
          </p>
          <p className="userdata">
            City :
            <span>{data.city === null ? "Not Defined Yet" : data.city}</span>
          </p>
          <p className="userdata">
            Zipcode :
            <span>
              {data.zipcode === null ? "Not Defined Yet" : data.zipcode}
            </span>
          </p>
          <p className="userdata">
            State :
            <span>{data.state === null ? "Not Defined Yet" : data.state}</span>
          </p>
          <p className="userdata">
            Device Type :
            <span>
              {data.device_type === null ? "Not Defined Yet" : data.device_type}
            </span>
          </p>
          {data.package_name === null ? (
            ""
          ) : (
            <p className="userdata">
              Package Name :{" "}
              <span style={{ color: "blue" }}>{data.package_name}</span>
            </p>
          )}
          <p className="userdata">
            Insurance :
            <span>
              {data.insurance === null ? "Not Defined Yet" : data.insurance}
            </span>
          </p>
          {data.insurance === null ? (
            ""
          ) : (
            <p
              className="userdata userdata_img"
              onClick={() => {
                setshowimage(true);
                setlink(
                  process.env.REACT_APP_IMAGE_URL_DRIVER + data.insurance_pic
                );
              }}
            >
              Insurance Image :
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL_DRIVER + data.insurance_pic
                }
                alt="insurance"
              />
            </p>
          )}
          <p className="userdata">
            License Number :
            <span>
              {data.license_number === null
                ? "Not Defined Yet"
                : data.license_number}
            </span>
          </p>
          {data.license_number === null ? (
            ""
          ) : (
            <p
              className="userdata userdata_img"
              onClick={() => {
                setshowimage(true);
                setlink(
                  process.env.REACT_APP_IMAGE_URL_DRIVER + data.license_pic
                );
              }}
            >
              License Image :
              <img
                src={process.env.REACT_APP_IMAGE_URL_DRIVER + data.license_pic}
                alt="licence"
              />
            </p>
          )}
          <p className="userdata">
            Daily Routes :
            <div className="userdata_route">
              {data.from_location === null ? (
                "Not Defined Yet"
              ) : (
                <>
                  <span>{data.from_location}</span>
                  <img src={route} alt="routes" />
                  <span>{data.to_location}</span>
                </>
              )}
            </div>
          </p>
          <p className="userdata">
            Status :{" "}
            {block ? (
              <span className="cancel_data">Deactive</span>
            ) : (
              <span className="complete_data">Active</span>
            )}
          </p>

          <div className="userreports">
            {block ? (
              <button
                className="useractive_button"
                onClick={() => active(data.id, setblock)}
              >
                Active
              </button>
            ) : (
              <button
                className="userdeactive_button"
                onClick={() => deactive(data.id, setblock)}
              >
                Deactivate
              </button>
            )}
          </div>
        </div>
      </div>
      {loading && <Loader />}
      {showimage && (
        <Imageview
          showimage={showimage}
          setshowimage={setshowimage}
          url={link}
        />
      )}
    </>
  );
};
export default DriverDetails;
