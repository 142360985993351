import { useState } from "react";
import { useEffect } from "react";
import Rating from "@mui/material/Rating";

import "./UserDetails.css";
import Loader from "../../Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { active, deactive, userdetails } from "../../service/Mainservice";
import Imageview from "../ImageView";

const UserDetails = () => {
  const [loading, setloading] = useState(true);
  const [showimage, setshowimage] = useState(false);
  const [link, setlink] = useState();
  const [data, setData] = useState([]);
  const [block, setblock] = useState();
  const location = useLocation();
  const id = location.state.id;
  const navigate = useNavigate();
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    try {
      const udata = await userdetails(id);
      setloading(false);
      setData(udata.data.data);
      setblock(udata.data.data.is_block);
    } catch (error) {
      setloading(false);

      if (error.code === 401) {
        localStorage.clear();
        navigate("/");
      }
    }
  };

  return (
    <>
      <h1 className="userdetails_heading">User Details </h1>
      <div className="userdetails">
        <div className="userdetails_up">
          <div className="userprofile_up">
            <img
              src={
                data?.profile_pic === undefined
                  ? ""
                  : process.env.REACT_APP_IMAGE_URL + data?.profile_pic
              }
              alt="img"
              onClick={() => {
                setshowimage(true);
                setlink(process.env.REACT_APP_IMAGE_URL + data.profile_pic);
              }}
            />
            <h1 className="username">
              {data.full_name === null ? "Not Defined Yet" : data.full_name}
            </h1>
          </div>
          <div className="userprofile_down" style={{ textAlign: "center" }}>
            <Rating
              name="read-only"
              value={Number(data.rate)}
              precision={0.5}
              size="large"
              readOnly
            />
            <p>({data.reviews} Reviews)</p>
          </div>
        </div>
        <div className="userdetails_down">
          <p className="userdata">
            Email :{" "}
            <span> {data.email === null ? "Not Defined Yet" : data.email}</span>
          </p>
          <p className="userdata">
            Phone Mumber :{" "}
            <span>
              {data.phone_number === null
                ? "Not Defined Yet"
                : +data.country_code + data.phone_number}
            </span>
          </p>
          <p className="userdata">
            Account Create :{" "}
            <span>
              {data.date_created === null
                ? "Not Defined Yet"
                : data.date_created}
            </span>
          </p>
          <p className="userdata">
            Language :{" "}
            <span>
              {data.Language === null ? "Not Defined Yet" : data.Language}
            </span>
          </p>
          <p className="userdata">
            Address1 :{" "}
            <span>
              {data.addressline_1 === null
                ? "Not Defined Yet"
                : data.addressline_1}
            </span>
          </p>
          <p className="userdata">
            Address2 :{" "}
            <span>
              {data.addressline_2 === null
                ? "Not Defined Yet"
                : data.addressline_2}
            </span>
          </p>
          <p className="userdata">
            City :{" "}
            <span>{data.city === null ? "Not Defined Yet" : data.city}</span>
          </p>
          <p className="userdata">
            Zipcode :{" "}
            <span>
              {data.zipcode === null ? "Not Defined Yet" : data.zipcode}
            </span>
          </p>
          <p className="userdata">
            State :{" "}
            <span>{data.state === null ? "Not Defined Yet" : data.state}</span>
          </p>
          <p className="userdata">
            Device Type :{" "}
            <span>
              {data.device_type === null ? "Not Defined Yet" : data.device_type}
            </span>
          </p>
          <p className="userdata">
            Status :{" "}
            {block ? (
              <span className="cancel_data">Deactive</span>
            ) : (
              <span className="complete_data">Active</span>
            )}
          </p>
          <div className="userreports">
            {block ? (
              <button
                className="useractive_button"
                onClick={() => active(data.id, setblock)}
              >
                Active
              </button>
            ) : (
              <button
                className="userdeactive_button"
                onClick={() => deactive(data.id, setblock)}
              >
                Deactivate
              </button>
            )}
          </div>
        </div>
      </div>
      {showimage && (
        <Imageview
          showimage={showimage}
          setshowimage={setshowimage}
          url={link}
        />
      )}

      {loading && <Loader />}
    </>
  );
};
export default UserDetails;
