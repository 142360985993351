import { useState } from "react";
import { useEffect } from "react";

import Loader from "../../Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { orderdetails } from "../../service/Mainservice";
import { Rating } from "@mui/material";
import Imageview from "../ImageView";

const OrderDetails = () => {
  const [loading, setloading] = useState(true);
  const [showimage, setshowimage] = useState(false);
  const [link, setlink] = useState();
  const [data, setData] = useState([]);
  const location = useLocation();
  const id = location.state.id;
  const navigate = useNavigate();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    try {
      const data = await orderdetails(id);
      setloading(false);
      setData(data.data.data);
    } catch (error) {
      setloading(false);

      if (error.code === 401) {
        localStorage.clear();
        navigate("/");
      }
    }
  };

  return (
    <>
      <h1 className="userdetails_heading">Order Details </h1>
      <div className="userdetails">
        <div className="userdetails_up_div">
          <div className="userdetails_up" style={{ marginBottom: "20px" }}>
            <div className="userprofile_up">
              <h1 className="driver_heading">User:</h1>

              <img
                src={process.env.REACT_APP_IMAGE_URL + data.user_profile_pic}
                alt="img"
                onClick={() => {
                  setshowimage(true);
                  setlink(
                    process.env.REACT_APP_IMAGE_URL + data.user_profile_pic
                  );
                }}
              />
              <br />
              <Rating
                name="read-only"
                value={Number(data.user_rate)}
                precision={0.5}
                readOnly
              />
            </div>
            <div className="userprofile_down">
              <p className="userdata">
                Name : <span>{data.user_name}</span>
              </p>
              <p className="userdata">
                Email : <span>{data.user_email}</span>
              </p>
              <p className="userdata">
                Phone Mumber :{" "}
                <span>+{data.user_country_code + data.user_phone_number}</span>
              </p>
            </div>
          </div>
          <div className="userdetails_up">
            <div className="userprofile_up">
              <h1 className="driver_heading">Driver:</h1>

              <img
                src={process.env.REACT_APP_IMAGE_URL + data.driver_profile_pic}
                alt="img"
                onClick={() => {
                  setshowimage(true);
                  setlink(
                    process.env.REACT_APP_IMAGE_URL + data.driver_profile_pic
                  );
                }}
              />
              <br />
              <Rating
                name="read-only"
                value={Number(data.driver_rate)}
                precision={0.5}
                readOnly
              />
            </div>
            <div className="userprofile_down">
              <p className="userdata">
                Name : <span>{data.driver_name}</span>
              </p>
              <p className="userdata">
                Email : <span>{data.driver_email}</span>
              </p>
              <p className="userdata">
                Phone Mumber :{" "}
                <span>
                  +{data.driver_country_code + data.driver_phone_number}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="userdetails_down">
          <p className="userdata">
            Status :{"    "}
            {data.order_status === 1 ? (
              <span className="pending_data">Pending</span>
            ) : data.order_status === 2 ? (
              <span className="upcoming_data">Upcoming</span>
            ) : data.order_status === 3 ? (
              <span className="start_data">Trip Started</span>
            ) : data.order_status === 4 ? (
              <span className="cancel_data">Canceled</span>
            ) : (
              <span className="complete_data">Completed</span>
            )}
          </p>
          <p className="userdata">
            Booking Date : <span>{data.booking_date}</span>
          </p>
          <p className="userdata">
            Booking Time : <span>{data.booking_time}</span>
          </p>
          {data.order_status === 5 ? (
            <p className="userdata">
              Completed On : <span>{data.date_completed}</span>
            </p>
          ) : null}
          <p className="userdata">
            Order Created :
            <span>
              {data.date_created === null
                ? "Not Defined Yet"
                : data.date_created}
            </span>
          </p>
          <p className="userdata">
            Description :
            <span>
              {data.description === null ? "Not Defined Yet" : data.description}
            </span>
          </p>
          <p className="userdata">
            Height :
            <span>
              {data.height === null ? "Not Defined Yet" : data.height}
            </span>
          </p>
          <p className="userdata">
            Width :
            <span>{data.width === null ? "Not Defined Yet" : data.width}</span>
          </p>
          <p className="userdata">
            Weight :
            <span>
              {data.weight === null ? "Not Defined Yet" : data.weight}
            </span>
          </p>
          <p className="userdata">
            Pick Address :
            <span>
              {data.pick_address === null
                ? "Not Defined Yet"
                : data.pick_address}
            </span>
          </p>
          <p className="userdata">
            Drop Address :
            <span>
              {data.drop_address === null
                ? "Not Defined Yet"
                : data.drop_address}
            </span>
          </p>
          <p className="userdata">
            Distance :
            <span>{data.km === null ? "Not Defined Yet" : data.km}KM</span>
          </p>

          <p className="userdata">
            Price :
            <span>{data.price === null ? "Not Defined Yet" : data.price}</span>
          </p>
        </div>
      </div>
      {showimage && (
        <Imageview
          showimage={showimage}
          setshowimage={setshowimage}
          url={link}
        />
      )}

      {loading && <Loader />}
    </>
  );
};
export default OrderDetails;
