import MainLayout from "../Layout/MainLayout";
import Editprofile from "../component/Profile/EditProfile";
import Profile from "../component/Profile/Profile";
import Dashboard from "../component/Dashboard/Dashboard";
import Policy from "../component/PrivacyPolicy";
import About from "../component/About Us";
import Terms from "../component/Terms&Condition";
import UserList from "../component/User/User";
import UserDetails from "../component/User/UserDetails";
import DriverDetails from "../component/Driver/DriverDetails";
import DriverList from "../component/Driver/Driver";
import OrderList from "../component/Order/Order";
import OrderDetails from "../component/Order/OrderDetails";
import ChangePassword from "../component/Profile/ChangePassword";
import ProtectedRoute from "../Protected Routes/ProtectedRoute";

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  element: <MainLayout />,
  children: [
    {
      path: "/Dashboard",
      element: <Dashboard />,
    },
    {
      path: "/Profile",
      element: <Profile />,
    },
    {
      path: "/EditProfile",
      element: <Editprofile />,
    },
    {
      path: "/ChangePassword",
      element: <ChangePassword />,
    },
    {
      path: "/User_List",
      element: <UserList />,
    },
    {
      path: "/User_Details",
      element: <UserDetails />,
    },
    {
      path: "/Driver_List",
      element: <DriverList />,
    },
    {
      path: "/Driver_Details",
      element: <DriverDetails />,
    },
    {
      path: "/Order_List",
      element: <OrderList />,
    },
    {
      path: "/Order_Details",
      element: <OrderDetails />,
    },
    {
      path: "/PrivacyPolicy",
      element: <Policy />,
    },
    {
      path: "/AboutUs",
      element: <About />,
    },
    {
      path: "/Terms&Condition",
      element: <Terms />,
    },
  ],
};

const MainRoutesWithProtection = {
  element: <MainLayout />,
  children: MainRoutes.children.map((route) => ({
    ...route,
    element: <ProtectedRoute>{route.element}</ProtectedRoute>,
  })),
};

export default MainRoutesWithProtection;
