import React, { useEffect, useState } from "react";
// import { API_URL } from "../../service";
import "./Dashboard.css";
// import ReactApexChart from "react-apexcharts";
import PersonIcon from "@mui/icons-material/Person";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { dashboard } from "../../service/Mainservice";
import Loader from "../../Loader/Loader";
import { useNavigate } from "react-router-dom";
// import Counter from "./Animatedcounter";

const Dashboard = () => {
  const [loading, setloading] = useState(true);
  const [details, setdetails] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    try {
      const data = await dashboard();
      setloading(false);
      setdetails(data.data.data);
    } catch (error) {
      setloading(false);
      if (error.code === 401) {
        localStorage.clear();
        navigate("/");
      }
    }
  };

  // const option = {
  //   labels: ["Private Account", "Public Account"],
  //   fill: {
  //     // type: "gradient",
  //     colors: ["#ed8283", "#ca6364"],
  //   },
  //   title: {
  //     text: "Private and Public Account",
  //   },
  //   responsive: [
  //     {
  //       breakpoint: 1150,
  //       options: {
  //         legend: {
  //           position: "bottom",
  //         },
  //       },
  //     },
  //     {
  //       breakpoint: 1050,
  //       options: {
  //         chart: {
  //           width: 300,
  //         },
  //       },
  //     },
  //     {
  //       breakpoint: 900,
  //       options: {
  //         chart: {
  //           width: 400,
  //         },
  //         legend: {
  //           position: "right",
  //         },
  //       },
  //     },
  //     {
  //       breakpoint: 450,
  //       options: {
  //         chart: {
  //           width: 350,
  //         },
  //         legend: {
  //           position: "bottom",
  //         },
  //       },
  //     },
  //   ],
  // };
  // const series = [2000, 1000];

  return (
    <>
      <div className="card_list">
        <div className="card">
          <div className="card_icon">
            <PersonIcon className="dashboard_icon" />
          </div>
          <div className="card_data">
            <h1 className="dashboard_counter">{details?.user_count}</h1>

            <p>Total Users</p>
          </div>
        </div>
        <div className="card">
          <div className="card_icon">
            <LocalShippingIcon className="dashboard_icon" />
          </div>
          <div className="card_data">
            <h1 className="dashboard_counter">{details?.driver_count}</h1>

            <p>Total Drivers</p>
          </div>
        </div>
        <div className="card">
          <div className="card_icon">
            <ShoppingCartIcon className="dashboard_icon" />
          </div>
          <div className="card_data">
            <h1 className="dashboard_counter">
              {details?.order_count}
              {/* <Counter number={details.order_count} /> */}
            </h1>
            <p>Total Orders</p>
          </div>
        </div>
      </div>
      {/* <div id="chart">
        <ReactApexChart
          options={option}
          series={series}
          type="donut"
          width={380}
        />
      </div> */}
      {loading && <Loader />}
    </>
  );
};
export default Dashboard;
