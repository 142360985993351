import "./Loader.css";
import logo from "../assets/LOGO_SWISSMOOOVING.png";

const Loader = () => (
  <div className="loaderdiv">
    <img src={logo} alt="logo" />
  </div>
);

export default Loader;
