import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { HeaderProvider } from "./context/HeaderContext";

const root = document.getElementById("root");
ReactDOM.render(
  <BrowserRouter>
    <HeaderProvider>
      <App />
    </HeaderProvider>
  </BrowserRouter>,
  root
);
