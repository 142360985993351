import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { orderlist, driverlist, userlist,
   getFile 
  } from "../../service/Mainservice";
import Pagination from "../Pagination/pagination";
import SearchIcon from "@mui/icons-material/Download";
import Loader from "../../Loader/Loader";
import Select from 'react-select'
// import logo from "../../assets/route.png"

const OrderList = () => {
  const [loading, setloading] = useState(true);
  const [data, setData] = useState([]);
  const [pagination, setpagination] = useState({});
  const [ userList, setUserList ] = useState([])
  const [ driverList, setDriverList ] = useState([])
  const [selectedDriver, setSelectedDriver] = useState()
  const [selectedUser, setSelectedUser] = useState()
  const [pick, setPick ] =useState("")
  const [drop, setDrop ] =useState("")
  const navigate = useNavigate();
  

  useEffect(() => {
    getData(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getUserList = async() => {
      try{
        const data = await userlist();
        const users = data.data.data
        const ModifiedUsers = users.map(item => ({
          value: item.id,
          label: item.full_name
        }));
        setUserList(ModifiedUsers)
       
      }catch(error){
        console.log(error)
      }
    }

    getUserList()
  }, [])

  useEffect(() => {
    const getDriverList = async() => {
      try{
        const data = await driverlist();
        const users = data.data.data
        const ModifiedUsers = users.map(item => ({
          value: item.id,
          label: item.full_name
        }));
        setDriverList(ModifiedUsers)
       
      }catch(error){
        console.log(error)
      }
    }

    getDriverList()
  }, [])

  const getData = async (page, userId , driverId , pick, drop) => {
    try {
      const data = await orderlist(page, userId || undefined, driverId || undefined, pick || undefined, drop || undefined) ;
      setloading(false);
      setData(data.data.data);
      setpagination(data.data.pagination);
    } catch (error) {
      setloading(false);
      if (error.code === 401) {
        localStorage.clear();
        navigate("/");
      }
    }
  };

  const handlePageChange = (pageNumber) => {
    getData(pageNumber);
  };

  const handleDriverChange = (option) => {
    setSelectedDriver(option.value);
    getData(1,selectedUser, option.value, pick, drop)
  };

  const handleUserChange = (option) => {
    setSelectedUser(option.value);
    getData(1, option.value, selectedDriver, pick, drop)
  };

  const handlePickChange = (e) => {
    setPick(e.target.value)
    getData(1, selectedUser, selectedDriver, e.target.value , drop)
  }

  const handleDropChange = (e) => {
    setDrop(e.target.value)
    getData(1, selectedUser, selectedDriver, pick, e.target.value)
  }

  const handleGetFile = async() => {
    try{
      const data = await getFile();
      console.log("data", data.status);
      if(data.status === 200){

        const blob = new Blob([data.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a link element
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute('download', 'file.xlsx');
    
        // Append link, trigger click and clean up
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
 
      }
     
    }catch(error){
      console.log("Error", error)
    }
  }
  
  return (
    <>
      <div className="container1">
        <div className="data_head">
          
          <div className="filter_main">
            <h1 className="data_heading_order">All Order List</h1>
            <Select className="selectfieldOrder" placeholder="Select Driver" options={driverList} onChange={handleDriverChange}/>
            <Select className="selectfieldOrder" placeholder="Select user" options={userList} onChange={handleUserChange}/>
            <input
              className="selectfield"
              type="search"
              placeholder="Search Pick"
              id="search"
              style={{marginLeft: "8px", width: "150px", height:"38px"}}
              value={pick}
              onChange={handlePickChange}
            />
            <input
              className="selectfield"
              type="search"
              placeholder="Search Drop"
              id="search"
              style={{marginLeft: "8px", width: "150px", height:"38px"}}
              value={drop}
              onChange={handleDropChange}
            />
          </div>

          {/* <div className="searchicon"> */}
            {/* <SearchIco/n /> */}
            <button
              onClick={handleGetFile}
              className="download_button_view"
            >
              <SearchIcon /> Export excel
            </button>
          {/* </div> */}
        </div>
        <div className="data_table_over">
          <table className="data_table">
            <thead>
              <tr>
                {/* <th>SL</th> */}
                <th>Driver</th>
                <th>User</th>
                <th>Booking Date</th>
                <th>Booking Time</th>
                <th>Pick</th>
                <th>Drop</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr key={item.id}>
                  {/* <td>{index + 1}</td> */}
                  <td>
                    <div className="filter_main">
                      <div className="order_profile_img">
                        <img src={item.driver_profile_pic} alt="driverImg"/>
                      </div>
                      <p>{item.driver_name}</p>
                    </div>
                  </td>
                  <td>
                  <div className="filter_main">
                      <div className="order_profile_img">
                        <img src={item.user_profile_pic} alt="userImg"/>
                      </div>
                      <p>{item.user_name }</p>
                    </div>
                  </td>
                  <td>{item.booking_date}</td>
                  <td>{item.booking_time}</td>
                  <td>{item.pick_address}</td>
                  <td>{item.drop_address}</td>
                  <td>
                    {item.order_status === 1 ? (
                      <span className="pending_data">Pending</span>
                    ) : item.order_status === 2 ? (
                      <span className="upcoming_data">Upcoming</span>
                    ) : item.order_status === 3 ? (
                      <span className="start_data">Trip Started</span>
                    ) : item.order_status === 4 ? (
                      <span className="cancel_data">Canceled</span>
                    ) : (
                      <span className="complete_data">Completed</span>
                    )}
                  </td>

                  <td>
                    <button
                      onClick={() =>
                        navigate("/Order_Details", {
                          state: {
                            id: item.id,
                          },
                        })
                      }
                      className="data_button_view"
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination pagedata={pagination} onPageChange={handlePageChange} />
      </div>
      {loading && <Loader />}
    </>
  );
};
export default OrderList;
