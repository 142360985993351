import React from "react";
import { Alert, Snackbar } from "@mui/material";
import OTPInput from "otp-input-react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom/dist";
import { otpverify } from "../../service/Authservice";

const OTPverify = () => {
  const [otp, setotp] = useState(Number);
  const [open, setOpen] = useState(false);
  const [error, seterror] = useState(false);
  const [otperror, setotperror] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const token = location.state.token;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleverify = async () => {
    if (otp.length === 0) {
      setotperror("Required");
    } else if (otp.length !== 6) {
      setotperror("OTP must be 6 Characters");
    } else {
      setotperror("");
      try {
        const data = await otpverify(otp, token);
        navigate("/ResetPassword", {
          state: {
            token: data.data.token,
          },
        });
      } catch (error) {
        seterror(error.message);
        setOpen(true);
      }
    }
  };
  return (
    <>
      <div className="App">
        <div className="Auth-form-container1">
          <div className="Auth-form1">
            <div className="Auth-form-content1">
              <h3 className="Auth-form-title">Verify OTP</h3>
              <div
                style={{
                  padding: 12,
                }}
              >
                <OTPInput
                  value={otp}
                  onChange={setotp}
                  className="otp_field"
                  name="otp"
                  inputClassName="bottom__border"
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  inputStyles={{
                    borderRadius: 10,
                    outline: 0,
                    border: "0.5px solid #e9e6e6",
                    padding: "10px 8px",
                    marginRight: 10,
                    boxShadow: "0px 0px 30px 0px #00000012",
                    fontSize: "18px",
                  }}
                  resendOTP={{}}
                />
              </div>
              <p className="invalid">{otperror} </p>
              <button
                type="submit"
                className="Auth_button"
                onMouseEnter={(e) => (e.target.style.transform = "scale(1.05)")}
                onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
                onClick={handleverify}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};
export default OTPverify;
